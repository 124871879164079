import styled from "styled-components"

export const Banner = styled.section`
  height: calc(100vh - 90px);
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 599px) {
    height: auto;
    margin-top: 100px;
    margin-bottom: 100px;
  }
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;
`

export const WeAre = styled.span`
  color: ${({ theme: { logoText } }) => logoText};
  font-weight: 100;
  font-size: 4rem;
  margin-right: 10px;

  @media (max-width: 599px) {
    font-size: 1.5rem;
    margin-right: 3px;
  }
`
export const Logo = styled.span`
  color: ${({ theme: { logoText } }) => logoText};
  font-size: 5rem;
  font-weight: 600;

  @media (max-width: 599px) {
    font-size: 2rem;
  }
`
export const Dot = styled.span`
  color: ${({ theme: { primary } }) => primary};
  border-radius: 100%;
  font-size: 75px;
`

export const Description = styled.p`
  color: #979797;
  font-weight: 200;
  font-size: 1.4rem;
  width: 50%;
  text-align: center;
  margin-bottom: 75px;

  @media (max-width: 599px) {
    font-size: 1rem;
    width: 90%;
    line-height: 17px;
  }

  @media (min-width: 600px) {
    width: 80%;
    line-height: 27px;
  }
`
