import styled from "styled-components"
import Img from "gatsby-image"

export const Container = styled.section``

export const SectionHolder = styled.div`
  display: flex;
  margin-bottom: 100px;
  flex-direction: ${({ toggled }) => (!toggled ? "row" : "row-reverse")};

  @media (max-width: 900px) {
    flex-direction: column;
  }

  @media (max-width: 599px) {
    margin-bottom: 50px;
  }
`
export const SectionContainer = styled.div`
  flex: 1;
`

export const ProcessText = styled.p`
  color: ${({ theme: { text } }) => text};
  float: left;
  max-width: 85%;

  @media (max-width: 900px) {
    max-width: 100%;
  }
`

export const ProcessItem = styled.li`
  display: grid;
  grid-template-columns: 40px auto;
  margin-bottom: 30px;
`

export const CountContainer = styled.div`
  height: 35px;
  width: 35px;
  border-radius: 100%;
  border: 1px solid ${({ theme: { primary } }) => primary};
  text-align: center;
  line-height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme: { primary } }) => primary};
  font-weight: 500;
  font-size: 1.5rem;
  float: left;
`

export const BundleTitle = styled.h5`
  font-weight: 800;
  font-size: 1.85rem;
  color: ${({ theme: { text } }) => text};
  margin-bottom: 21px;
`

export const BundleDescription = styled.p`
  color: ${({ theme: { text } }) => text};
  font-weight: 200;
  font-size: 1.1rem;
  margin-bottom: 35px;
  max-width: 85%;

  @media (max-width: 900px) {
    max-width: 100%;
  }
`

export const TextStackContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`

export const TechImage = styled(Img)`
  height: 30px;
  margin-right: 15px;
`
