import React from "react"

// Components
import Layout from "../components/layout"
import Clients from "../components/Clients"
import Footer from "../components/Footer"
import SEO from "./../components/Seo"
import AboutWhatWeDo from "./../components/AboutWhatWeDo"

// styled components
import { Container } from "./../styles/GlobalStyles"
import {
  Banner,
  TitleContainer,
  WeAre,
  Logo,
  Dot,
  Description,
} from "./../styles/about"

const IndexPage = props => {
  return (
    <Layout>
      <SEO title="About Us" pathname={props.location.pathname} />
      <Banner>
        <TitleContainer>
          <p style={{ color: "#FFFFFF" }}>
            <WeAre>we are</WeAre>
            &nbsp;
            <Logo>mellowframes</Logo>
            &nbsp;
            <Dot>.</Dot>
          </p>
        </TitleContainer>
        <Description>
          We offer innovative multimedia creations in the service of product
          marketing. The most essential quality for a production house.
        </Description>
      </Banner>
      <Container>
        <AboutWhatWeDo />
      </Container>
      <Clients />
      <Footer />
    </Layout>
  )
}

export default IndexPage
