import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import {
  Container,
  SectionHolder,
  SectionContainer,
  ProcessText,
  CountContainer,
  ProcessItem,
  TextStackContainer,
  BundleTitle,
  BundleDescription,
  TechImage,
} from "./../styles/whatwedo"

const aboutContent = [
  {
    title: "Explainer Videos",
    content:
      "Explainer videos are basically short online marketing videos, that explains your company's product or service which are mostly placed on your website or prominent product page. Our service of creating explainer videos is to narrate your brand's story in a memorable way.",
    process: [
      {
        item_id: 1,
        item_des:
          "Grab viewer's attention on your product in a couple of minutes by making an explainer video about your product.",
      },
      {
        item_id: 2,
        item_des:
          "One good video equals 1.8M words, 3600 webpages. Thats a lot of reading!",
      },
      {
        item_id: 3,
        item_des:
          "People are more likely to buy from you if you have a video of your product on your website.",
      },
      {
        item_id: 4,
        item_des:
          "50% of users take further action in some way if they watch the product explainer video on your website.",
      },
    ],
  },
  {
    title: "Motion Graphics",
    content:
      "Motion graphics imbibes text, shapes, symbols and charts to illustrate complex ideas visually. No matter what industry you are in, the motion graphic videos can make your ideas more engaging and entertaining. We aim at delivering a visual style of motion graphics that appeals to a wide wide range of viewers.",
    process: [
      {
        item_id: 1,
        item_des:
          "Convey your ideas to your audience in an easy, engaging and effective way",
      },
      {
        item_id: 2,
        item_des:
          "A creative way to market your product or service to the customers",
      },
      {
        item_id: 3,
        item_des:
          "Aids in explainer videos to define a concept, product, service or organization",
      },
      {
        item_id: 4,
        item_des:
          "It also has the ability to change a boring content to an entertaining and engaging video content.",
      },
    ],
  },
  {
    title: "White Board Animations",
    content:
      "White board is a style of video designed to look as if the content is being hand written on a white board or background. It is useful for conveying detailed information in much simpler way.",
    process: [
      {
        item_id: 1,
        item_des:
          "White board animations are versatile. They are suitable to present educational videos to marketing content",
      },
      {
        item_id: 2,
        item_des:
          "The animation is a combination of sound, movements and visuals that holds your attention",
      },
      {
        item_id: 3,
        item_des:
          "Its never a work to create a white board animation, its always fun!",
      },
    ],
  },
  {
    title: "Our Process",
    content:
      "Its human nature to love good stories. That makes it obvious why many brands focus on crafted stories into their digital marketing. Check out the process of how we craft our work.",
    process: [
      {
        item_id: 1,
        item_des:
          "We gather requirements of your project to define corner cases and avoid any ambiguity during development",
      },
      {
        item_id: 2,
        item_des:
          "A good script delivers the idea in a better way. We write scripts that better defines the idea and convert them into storyboard to visualize the story",
      },
      {
        item_id: 3,
        item_des:
          "After the polished script, voice over artist comes in to narrate the story. We also provide voice overs in many different languages and accents",
      },
      {
        item_id: 4,
        item_des:
          "The plan from the storyboard is drawn into flawless illustrations and creative designs",
      },
      {
        item_id: 5,
        item_des:
          "On approval, the handcrafted designs are animated into eye catching motion graphics and transitions with the right sound effects",
      },
    ],
  },
]

const WorkType = props => {
  const data = useStaticQuery(graphql`
    query {
      magento: file(relativePath: { eq: "magento.png" }) {
        childImageSharp {
          fixed(height: 21, width: 66, fit: INSIDE) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      nodejs: file(relativePath: { eq: "nodejs.png" }) {
        childImageSharp {
          fixed(height: 39, width: 61, fit: INSIDE) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      react: file(relativePath: { eq: "magento.png" }) {
        childImageSharp {
          fixed(height: 28, width: 83, fit: INSIDE) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <SectionContainer>
      {/* <TextStackContainer>
        <TechImage fixed={data.magento.childImageSharp.fixed} alt="Magento" />
        <TechImage fixed={data.nodejs.childImageSharp.fixed} alt="Magento" />
        <TechImage fixed={data.react.childImageSharp.fixed} alt="Magento" />
      </TextStackContainer> */}
      <BundleTitle>{props.title}</BundleTitle>
      <BundleDescription>{props.content}</BundleDescription>
    </SectionContainer>
  )
}

const WorkProcess = props => {
  return (
    <SectionContainer>
      <ul>
        {props.process.map((_i, index) => (
          <ProcessItem>
            <CountContainer>{_i.item_id}</CountContainer>
            <ProcessText>{_i.item_des}</ProcessText>
          </ProcessItem>
        ))}
      </ul>
    </SectionContainer>
  )
}

const AboutWhatWeDo = () => {
  return (
    <Container>
      {aboutContent.map((item, index) => (
        <SectionHolder toggled={index % 2 !== 0}>
          <WorkType title={item.title} content={item.content} />
          <WorkProcess process={item.process} />
        </SectionHolder>
      ))}
    </Container>
  )
}

export default AboutWhatWeDo
